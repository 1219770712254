import { parse } from 'query-string';
import { getLang } from '../Utils/locale';
import { getDurationNumber } from '../Utils/utils';

const initialDuration = Number(parseInt(parse(window.location.search).duration));
const lang = getLang(parse(window.location.search).lang);

const initialState = {
  shopId: null,
  duration: initialDuration || 2,
  lang: lang || 'en',
  value: null,
  type: 'default',
  campaignName: null,
};

const getDefaultDuration = (priceModelDefault) => {
  let defaultDuration = initialState.duration;

  if (typeof priceModelDefault === 'number' && !Number.isNaN(priceModelDefault)) {
    defaultDuration = priceModelDefault;
  }

  if (typeof initialDuration === 'number' && !Number.isNaN(initialDuration)) {
    defaultDuration = initialDuration;
  }

  return defaultDuration;
};

const giftcardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GIFTCARD_UPDATE_GIFTCARD': {
      return {
        ...state,
        ...action.giftcard,
      };
    }
    case 'GIFTCARD_UPDATE_DURATION': {
      return {
        ...state,
        duration: Number(action.duration),
      };
    }
    case 'GIFTCARD_UPDATE_LANG': {
      return {
        ...state,
        lang: action.lang,
      };
    }
    case 'VOUCHERS_UPDATE': {
      return {
        ...state,
        duration: action.vouchers[0]?.changes?.giftcard?.duration ?? state.duration,
        type: action.vouchers[0]?.changes?.giftcard?.type ?? state.type,
        campaignName:
          action.vouchers[0]?.changes?.giftcard?.campaignName ?? state.campaignName,
      };
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS': {
      // Get default price model
      let priceModel = action.shop?.priceModels?.find((model) => model.default);
      const giftcardModel = action.shop?.priceModels?.find((model) => model.giftcards);

      if (giftcardModel.durations || giftcardModel.rates) priceModel = giftcardModel;

      // Set default duration
      const priceModelDefault = getDurationNumber(priceModel?.defaultDurationGiftcard);

      const defaultDuration = getDefaultDuration(priceModelDefault);

      return {
        ...initialState,
        shopId: action.shop.id,
        lang: state.lang,
        duration: defaultDuration,
      };
    }
    case 'PRICES_UPDATE_PRICES': {
      return {
        ...state,
        value: action.prices.rentalPrice,
      };
    }
    case 'LOCALE_UPDATE_LANG': {
      return {
        ...state,
        lang: action.lang,
      };
    }
    default: {
      return state;
    }
  }
};

export default giftcardReducer;
