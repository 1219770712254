import { parse } from 'query-string';

const initialState = {
  id: '',
};

const selected = parse(window.location.search).selected;

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS':
    case 'SHOP_FETCH_SHOP_SUCCESS_KEEP_BOOKING': {
      return action.shop;
    }
    case 'SHOP_FETCH_SHOP_ERROR': {
      return state;
    }
    case 'SHOPS_FETCH_SHOPS_SUCCESS': {
      // This functionality was previously in a useEffect in the booking page
      // but it makes more sense to have it as an response to an action
      // than as a useFffect that has to figure out when to run
      let visibleShops = [];

      if (action.shops.length > 0) {
        visibleShops = action.shops.filter((shop) => !shop.hidden);
      }

      const selectedShop = visibleShops.find(
        (shop) => shop.name === selected || shop.id === selected
      );

      if (selectedShop) {
        return selectedShop;
      }

      return state;
    }
    default: {
      return state;
    }
  }
};

export default shopReducer;
