import { v4 as uuidv4 } from 'uuid';

const initialState = {
  payments: [],
  stripe: null,
  uuid: uuidv4(),
  isLoading: false,
  isPaid: false,
  showPaymentModal: false,
  paymentIntentClientSecret: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PAYMENT_UPDATE_PAYMENTS': {
      return {
        ...state,
        payments: action.payments,
      };
    }
    case 'PAYMENT_UPDATE_STRIPE': {
      return {
        ...state,
        stripe: action.stripe,
      };
    }
    case 'PAYMENT_UPDATE_UUID': {
      return {
        ...state,
        uuid: uuidv4(),
      };
    }
    case 'PAYMENT_UPDATE_SHOW_PAYMENT_MODAL': {
      if (!state.isPaid) {
        return {
          ...state,
          showPaymentModal: action.show,
        };
      }

      return state;
    }
    case 'PAYMENT_UPDATE_IS_LOADING': {
      return {
        ...state,
        isLoading: action.loading,
      };
    }
    case 'PAYMENT_UPDATE_IS_PAID': {
      return {
        ...state,
        isPaid: action.paid,
      };
    }
    case 'SHOP_UPDATE_SHOP': {
      return {
        ...state,
        stripe: null,
        isPaid: false,
        showPaymentModal: false,
      };
    }
    case 'PAYMENT_INTENT_SET_CLIENT_SECRET': {
      return {
        ...state,
        paymentIntentClientSecret: action.paymentIntentClientSecret,
      };
    }
    case 'ERROR_PAYMENT_INTENT': {
      return {
        ...state,
        paymentIntentClientSecret: null,
      };
    }
    case 'BOOKING_UPDATE_BOOKING': {
      if (action.payments) {
        return {
          ...state,
          payments: action.payments,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default paymentReducer;
