import React from 'react';
import { ModalProvider } from 'styled-react-modal';

import './Style/GlobalStyles.css';
import { ModalBackground } from './SubComponents/Modals';
import State from './Contexts/State';
import StripeContextProvider from './Contexts/stripe-context';
import TimeslotsContextProvider from './Contexts/timeslots-context';
import DurationOptionsProvider from './Contexts/duration-options-context';
import * as Tooltip from '@radix-ui/react-tooltip';
import ShopContextProvider from './Contexts/shop-context';

interface ProvidersProps {
  children: React.ReactNode;
}

const Providers = (props: ProvidersProps) => {
  return (
    <ModalProvider backgroundComponent={ModalBackground}>
      <State>
        <ShopContextProvider>
          <StripeContextProvider>
            <TimeslotsContextProvider>
              <DurationOptionsProvider>
                <Tooltip.Provider>{props.children}</Tooltip.Provider>
              </DurationOptionsProvider>
            </TimeslotsContextProvider>
          </StripeContextProvider>
        </ShopContextProvider>
      </State>
    </ModalProvider>
  );
};

export default Providers;
