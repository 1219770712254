const initialState = {
  customer: {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    birthDate: '',
  },
  formValid: false,
  pristine: true,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_UPDATE_CUSTOMER': {
      return {
        ...state,
        customer: action.customer,
      };
    }
    case 'CUSTOMER_UPDATE_FORM_VALID': {
      return {
        ...state,
        formValid: action.formValid,
      };
    }
    case 'CUSTOMER_SET_PRISTINE': {
      return {
        ...state,
        pristine: action.pristine || false,
      };
    }
    case 'BOOKING_UPDATE_BOOKING': {
      if (action.customer) {
        return {
          ...state,
          customer: action.customer,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default customerReducer;
