import { parse } from 'query-string';
import { getLang } from '../Utils/locale';
import { getDurationNumber, getDurationString } from '../Utils/utils';
import { DockingLocationOperation } from '../Utils/graphql-sdk';

const initialDuration = Number(parseInt(parse(window.location.search).duration));
const lang = getLang(parse(window.location.search).lang);
const selected = parse(window.location.search).selected;

const initialState = {
  shopId: null,
  boatCount: 1,
  duration: initialDuration || 2,
  date: null,
  startTime: null,
  endTime: null,
  price: null,
  lang: lang || 'en',
  priceModelId: null,
  cancellationPolicyId: null,
  reservationId: null,
  pickupLocationId: null,
  dropoffLocationId: null,
};

const getDefaultDuration = (priceModelDefault) => {
  let defaultDuration = initialState.duration;

  if (typeof priceModelDefault === 'number' && !Number.isNaN(priceModelDefault)) {
    defaultDuration = priceModelDefault;
  }

  if (typeof initialDuration === 'number' && !Number.isNaN(initialDuration)) {
    defaultDuration = initialDuration;
  }

  return defaultDuration;
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'BOOKING_UPDATE_BOOKING': {
      return {
        ...state,
        ...action.booking,
      };
    }
    case 'BOOKING_UPDATE_BOAT_COUNT': {
      return {
        ...state,
        ...(action.keepTimeslot
          ? {}
          : {
              date: null,
              startTime: null,
              endTime: null,
            }),
        boatCount: action.boatCount,
      };
    }
    case 'BOOKING_UPDATE_DURATION': {
      return {
        ...state,
        ...(action.keepTimeslot
          ? {
              endTime: getDurationString(
                getDurationNumber(state.startTime) + Number(action.duration)
              ),
            }
          : {
              date: null,
              startTime: null,
              endTime: null,
            }),
        duration: Number(action.duration),
      };
    }
    case 'BOOKING_UPDATE_PICKUP_LOCATION': {
      return {
        ...state,
        ...(action.keepTimeslot
          ? {}
          : {
              date: null,
              startTime: null,
              endTime: null,
            }),
        pickupLocationId: action.pickupLocationId,
      };
    }
    case 'BOOKING_UPDATE_DROPOFF_LOCATION': {
      return {
        ...state,
        ...(action.keepTimeslot
          ? {}
          : {
              date: null,
              startTime: null,
              endTime: null,
            }),
        dropoffLocationId: action.dropoffLocationId,
      };
    }
    case 'BOOKING_UPDATE_DATE': {
      return {
        ...state,
        date: action.date,
      };
    }
    case 'BOOKING_UPDATE_START_TIME': {
      return {
        ...state,
        startTime: action.startTime,
      };
    }
    case 'BOOKING_UPDATE_END_TIME': {
      return {
        ...state,
        endTime: action.endTime,
      };
    }
    case 'BOOKING_UPDATE_PRICE': {
      return {
        ...state,
        price: action.price,
      };
    }
    case 'BOOKING_UPDATE_LANG': {
      return {
        ...state,
        lang: action.lang,
      };
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS': {
      // Get default price model
      const priceModel = action.shop?.priceModels?.find((model) => model.default);

      // Set default duration
      const priceModelDefault = getDurationNumber(priceModel?.defaultDurationBooking);

      const defaultDuration = getDefaultDuration(priceModelDefault);

      // Set default pickup and dropoff location
      const defaultPickUpLocation = action.shop?.dockingLocations?.find(
        (location) =>
          location?.default &&
          location?.operations.includes(DockingLocationOperation.Pickup)
      );
      const defaultDropOffLocation = action.shop?.dockingLocations?.find(
        (location) =>
          location?.default &&
          location?.operations.includes(DockingLocationOperation.Dropoff)
      );

      return {
        ...initialState,
        shopId: action.shop.id,
        lang: state.lang,
        duration: defaultDuration,
        pickupLocationId: defaultPickUpLocation?.id,
        dropoffLocationId: defaultDropOffLocation?.id,
      };
    }
    case 'SHOPS_FETCH_SHOPS_SUCCESS': {
      let visibleShops = [];

      if (action.shops.length > 0) {
        visibleShops = action.shops.filter((shop) => !shop.hidden);
      }

      const selectedShop = visibleShops.find(
        (shop) => shop.name === selected || shop.id === selected
      );

      if (selectedShop) {
        // This is the same logic from the SHOP_UPDATE_SHOP and SHOP_FETCH_SHOP_SUCCESS cases
        // we might want to extract this logic into a separate function at some point

        // Get default price model
        const priceModel = selectedShop?.priceModels?.find((model) => model.default);

        // Set default duration
        const priceModelDefault = getDurationNumber(priceModel?.defaultDurationBooking);

        const defaultDuration = getDefaultDuration(priceModelDefault);

        // Set default pickup and dropoff location
        const defaultPickUpLocation = selectedShop?.dockingLocations?.find(
          (location) =>
            location?.default &&
            location?.operations.includes(DockingLocationOperation.Pickup)
        );
        const defaultDropOffLocation = selectedShop?.dockingLocations?.find(
          (location) =>
            location?.default &&
            location?.operations.includes(DockingLocationOperation.Dropoff)
        );

        return {
          ...initialState,
          shopId: selectedShop.id,
          lang: state.lang,
          duration: defaultDuration,
          pickupLocationId: defaultPickUpLocation?.id,
          dropoffLocationId: defaultDropOffLocation?.id,
        };
      }

      return state;
    }
    case 'PRICES_UPDATE_PRICES': {
      return {
        ...state,
        price: action.prices.fullPrice,
      };
    }
    case 'LOCALE_UPDATE_LANG': {
      return {
        ...state,
        lang: action.lang,
      };
    }
    case 'PRICES_UPDATE_PRICE_MODEL': {
      return {
        ...state,
        priceModelId: action.priceModel?.id,
      };
    }
    case 'CANCELLATION_POLICY_UPDATE': {
      return {
        ...state,
        cancellationPolicyId: action.cancellationPolicy?.id,
      };
    }
    default: {
      return state;
    }
  }
};

export default bookingReducer;
