import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Router, Redirect } from '@reach/router';
import 'focus-visible';
import 'whatwg-fetch';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Providers from './providers';

import './Style/GlobalStyles.css';
import Helmet from './Helmet';
import useLegacyRedirect from './Hooks/useLegacyRedirect';
import { getConfig } from './Utils/utils';

const Booking = lazy(() => import('./Pages/Booking'));
const BookingEdit = lazy(() => import('./Pages/BookingEdit'));
const Giftcard = lazy(() => import('./Pages/Giftcard'));
const Membership = lazy(() => import('./Pages/Membership'));
const MembershipPlans = lazy(() => import('./Pages/Membership-plans'));
const StripeElementsWrapper = lazy(() => import('./Components/stripe-elements-wrapper'));

const { sentryEnvironment, sentryEnabled, sentryRelease } = getConfig();

if (sentryEnabled === 'true') {
  Sentry.init({
    dsn: 'https://5316663455af4091a50e3680ebac74f0@o1150551.ingest.sentry.io/6223537',
    integrations: [new BrowserTracing()],
    environment: sentryEnvironment,
    release: sentryRelease,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in banana production
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  useLegacyRedirect([
    { from: '#!/bookingCreate', to: 'booking' },
    { from: '#!/giftcards', to: 'giftcard' },
    { from: '#!/payment', to: 'booking', dynamic: true },
  ]);

  return (
    <Providers>
      <Helmet />
      <Suspense fallback={<></>}>
        <Router primary={false}>
          <Redirect noThrow from="/" to="/booking" />
          <Redirect noThrow from="/book" to="/booking" />
          <BookingEdit path="booking/:bookingId/*" />
          <Booking path="booking" />
          <StripeElementsWrapper path="giftcard" component={Giftcard} />
          <StripeElementsWrapper path="goboat-plus/:planId" component={Membership} />
          <MembershipPlans path="goboat-plus" />
          <BookingEdit path="sauna/:bookingId/*" />
          <Booking path="sauna" />
        </Router>
      </Suspense>
    </Providers>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
